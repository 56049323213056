
<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 mb-5 pb-5' : 'mt-3' ]">
    <b-row>
        <b-col> 
          <cal class="mb-4" @updatePending="updatePending"></cal>
        </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
          <pending-jobs getJobType="roofing" title="Roofing" variant="primary" ref="roofingJobs"/>
      </b-col>
      <b-col sm="4">
          <pending-jobs getJobType="siding" title="Siding & Windows" variant="secondary" ref="sidingJobs"/>
      </b-col>
      <b-col sm="4">
          <pending-jobs getJobType="other" title="Repairs, Gutters & Other" variant="info" ref="otherJobs"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PendingJobs from '../components/jobs-components/pending-jobs.vue'
import Cal from '../components/jobs-components/cal.vue'


export default {
  name: "jobs",
  components: {
    PendingJobs,
    Cal
  },
  data() {
    return {
    
    }
  },
  computed: {
      isMobile() {
        return this.$state.isMobile
      }
  },
  methods: {
    updatePending(val) {
      //trigger pendingJob components to update
      console.log(`Cal Updated...`)
      if(val.toLowerCase() == 'rip reroof' || val.toLowerCase() == 'new roof' || val.toLowerCase() == 'cover roof' ) {
        console.log(`Update pending Roofing jobs components : ${val}`)
        this.$refs.roofingJobs.getPendingJobs()
      } else if (val.toLowerCase() == 'siding' || val.toLowerCase() == 'windows' || val.toLowerCase() == 'trim job') {
        console.log(`Update pending Siding jobs components : ${val}`)
        this.$refs.sidingJobs.getPendingJobs()
      } else {
        console.log(`Update pending jobs Other components : ${val}`)
        this.$refs.otherJobs.getPendingJobs()
      }
    },
  },
  created() {
    
  }
}
</script>


<style lang="scss" scoped>

</style>
