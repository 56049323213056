<template>
    <div>
        <b-card sub-title="Jobs with dates in the past" v-if="datesInThePast">
            <div class="d-flex">
                <div v-for="job in items" :key="job._id" class="">
                    <b-button variant="outline-primary" v-if="job.orphanedBefore" class="mr-1" @click="orphanedEventClick(job)">
                        {{job.name}} <small>{{job.type}}</small> <small>{{format(job.endDate, "MM/dd/yyyy")}}</small>
                    </b-button>
                </div>
            </div>
        </b-card>
        <div class="gantt mt-3" >
            <calendar-view 
                :show-date="showDate" 
                :items="styledItems" 
                displayPeriodUom="week" 
                :displayPeriodCount="2" 
                class="theme-default holiday-us-traditional holiday-us-official"
                @click-item="calEventClick"
                itemContentHeight="1.6em">
            </calendar-view>
        </div>
        <br>
        <b-card sub-title="Jobs with dates in the future" v-if="datesInTheFuture">
            <div class="d-flex">
            <div v-for="job in items" :key="job._id" class="">
                <b-button variant="outline-primary" v-if="job.orphanedAfter" class="mr-1" @click="orphanedEventClick(job)">
                    {{job.name}} <small>{{job.type}}</small> <small>{{format(job.startDate, "MM/dd/yyyy")}}</small>
                </b-button>
            </div>
        </div>
        </b-card>
        

        <b-modal ref="event-modal" centered>
            <template #modal-header>
                <h5>{{selectedJob.name}}<br>
                <small>{{selectedJob.type}}</small></h5>
                <b-button size="sm" class="float-right" variant="outline-secondary" @click="gotoJobProfile(selectedJob._id)">
                   <font-awesome-icon icon="fa-solid fa-share-from-square" /> Go To Job Profile
                </b-button>
            </template>
            <b-container>
                <b-alert show variant="warning" v-if="selectedJob.jobType == 'Ticket'">This is a service ticket and all modification must be made on the ticket itself. Click on <b-badge>Go To Job Profile</b-badge> above and select the ticket to change dates and/or status.</b-alert>
                <b-form>
                    <b-form-group id="mechGroup" label-cols-sm="2" content-cols-sm="8" label="Mechanic" label-for="mechanic" :state="validForm.mechanic" invalid-feedback="You must enter a mechanic!">
                        <b-form-input id="mechanic" v-model="selectedJob.mechanic" class="" :state="validForm.mechanic" @keypress="valMechanic()" :disabled="selectedJob.jobType == 'Ticket'"></b-form-input>
                    </b-form-group>
                    <b-form-group id="startGroup" label-cols-sm="2" content-cols-sm="8" label="Start" label-for="startDate">
                        <b-form-datepicker value-as-date id="startDate" v-model="selectedJob.startDate" :state="validForm.endDate" :disabled="selectedJob.jobType == 'Ticket'"></b-form-datepicker>
                    </b-form-group>
                    <b-form-group id="endGroup" label-cols-sm="2" content-cols-sm="8" label="End" label-for="endDate" :state="validForm.endDate" invalid-feedback="End date must be after the start date!">
                            <b-form-datepicker value-as-date id="endDate" v-model="selectedJob.endDate" :state="validForm.endDate" @hidden="valEndDate" :disabled="selectedJob.jobType == 'Ticket'"></b-form-datepicker>
                    </b-form-group>
                    <b-form-group id="statusGroup" label-cols-sm="2" content-cols-sm="8" label="Status" label-for="status">
                        <b-form-select
                            id="status"
                            v-model="selectedJob.status"
                            :options="statusTypes" :disabled="selectedJob.jobType == 'Ticket'">
                        </b-form-select>
                    </b-form-group>
                </b-form>
            </b-container>
            <template #modal-footer>
                <b-button size="sm" variant="outline-danger" @click="hideModal()">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" @click="updateEvent()" :disabled="selectedJob.jobType == 'Ticket'">
                    <font-awesome-icon icon="fa-regular fa-floppy-disk" /> Update
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
//reference for vue-simple-calander at https://github.com/richardtallent/vue-simple-calendar
import { CalendarView } from "vue-simple-calendar"
import "../jobs-components/cal.css"
import "vue-simple-calendar/static/css/holidays-us.css"
import { fromUnixTime } from 'date-fns'
import { getUnixTime } from 'date-fns'
import { isBefore } from 'date-fns'
import { isAfter } from 'date-fns'
import { startOfWeek } from 'date-fns'
import { endOfWeek } from 'date-fns'
import { addDays } from 'date-fns'
import { format } from 'date-fns'

export default {
    name: 'Cal',
    components: {
        CalendarView
    },
    computed: {
        items () {
            return this.$state.calItems
        },
        styledItems () {
            let counter = 0
            let styledItems = this.items.map(item => {
                item.style = `background-color: ${this.itemColors[counter].back}; 
                              color: ${this.itemColors[counter].fore};`
                item.classes = ["padded-item"]
                if(item.jobType.toLowerCase() === 'rip reroof' || item.jobType.toLowerCase() === 'roof repair' || item.jobType.toLowerCase() === 'new construction'){
                    item.url = this.roofingIconUrl
                } else if(item.jobType.toLowerCase() === 'windows' || item.jobType.toLowerCase() === 'window repair') {
                    item.url = this.windowIconUrl
                } else {
                    item.url = this.sidingIconUrl
                }
                item.title = `<img src="${item.url}" style="width:18px;height:18px;"> ${item.name}&nbsp;<small>${item.mechanic}</small>`
                counter++
                if(counter == 4){
                    counter = 0
                }
                return item
            })
            this.serviceItems.forEach(item => {
                item.style = `background-color: ${this.itemColors[counter].back}; 
                              color: ${this.itemColors[counter].fore};`
                item.classes = ["padded-item"]
                item.title = `<img src="${this.ticketIconUrl}" style="width:18px;"> ${item.name}&nbsp;<small>${item.mechanic}</small>`
                counter++
                if(counter == 4){
                    counter = 0
                }
                styledItems.push(item)
            })

            return styledItems
        }     
    },
    data() {
        return {
            showDate: new Date(),
            itemColors: [
                {"back": "#0275d8", "fore": "#f7f7f7"}, 
                {"back": "#5cb85c", "fore": "#f7f7f7"}, 
                {"back": "#f0ad4e", "fore": "#292b2c"}, 
                {"back": "#5bc0de", "fore": "##292b2c"}
            ],
            roofingIconUrl: require('@/assets/img/icons/roofingIcon.png'),
            windowIconUrl: require('@/assets/img/icons/windowIcon.png'),
            sidingIconUrl: require('@/assets/img/icons/sidingIcon.png'),
            ticketIconUrl: require('@/assets/img/icons/ticketIcon.png'),
            selectedJob: {},
            statusTypes: ['pending', 'started', 'finished'],
            validForm: {
                mechanic: null,
                endDate: null
            },
            format: format,
            datesInTheFuture: false,
            datesInThePast: false,
            serviceItems: []
        }
    },
    methods: {
        setShowDate(d) {
			this.showDate = d;
		},
        async getInProgressJobs() {

            try {

                    const res = await this.$axios.get("/job/inprogress", {
                                headers: { Authorization: `Bearer ${this.$state.token}` }
                            })
                    let jobs = res.data
                    console.log(`inProgress jobs total: ${jobs.length}`)
                    //console.log(res.data)

                    let events = jobs.map(event => {
                        let thisjob = {}
                        thisjob.name = `${event.customer.lname}, ${event.customer.fname}`
                        thisjob.mechanic = event.mechanic
                        thisjob.jobType = event.jobType
                        thisjob._id = event._id
                        thisjob.startDate = fromUnixTime(event.dates.startDate)
                        thisjob.endDate = fromUnixTime(event.dates.finishedDate)
                        //thisjob.title = `<img src="${this.sidingIconUrl}" style="width:18px;"> ${thisjob.name}&nbsp;<small>${thisjob.mechanic}</small>`
                        thisjob.url = `/jobProfile/${thisjob._id}`
                        thisjob.status = event.status
                        
                        //check if jobs are before or after the displayed calander
                        //the date of the first sunday displayed on the calender is
                        let firstSunday = startOfWeek(new Date())
                        let endOfNextWeek = addDays(endOfWeek(new Date()), 7)
                        //is the endDate of this job before 'firstSunday'?
                        thisjob.orphanedBefore = isBefore(thisjob.endDate, firstSunday)
                        if(thisjob.orphanedBefore) {
                            this.datesInThePast = true
                        }
                        //is the startDate of this job after 'EndOfNextWeek'?
                        thisjob.orphanedAfter = isAfter(thisjob.startDate, endOfNextWeek)
                        if(thisjob.orphanedAfter) {
                            this.datesInTheFuture = true
                        }

                        return thisjob
                    })                 

                this.$state.calItems = events

            } catch (err) {
                console.log(err)
            }
            

            try {

                const res = await this.$axios.get("/service/inprogress", {
                            headers: { Authorization: `Bearer ${this.$state.token}` }
                        })
                let tix = res.data
                console.log(`inProgress tix total: ${tix.length}`)
                //console.log(res.data)

                let tixEvents =  tix.map(event => {
                    let thisTix = {}
                    thisTix.name = `${event.customer.lname}, ${event.customer.fname}`
                    thisTix.mechanic = event.serviceMan
                    thisTix.jobType = 'Ticket'
                    thisTix._id = event.job
                    thisTix.startDate = fromUnixTime(event.dates.startDate)
                    thisTix.endDate = fromUnixTime(event.dates.finishedDate)
                    //thisjob.title = `<img src="${this.sidingIconUrl}" style="width:18px;"> ${thisjob.name}&nbsp;<small>${thisjob.mechanic}</small>`
                    thisTix.url = `/jobProfile/${thisTix._id}`
                    thisTix.status = event.status
                    
                    //check if jobs are before or after the displayed calander
                    //the date of the first sunday displayed on the calender is
                    let firstSunday = startOfWeek(new Date())
                    let endOfNextWeek = addDays(endOfWeek(new Date()), 7)
                    //is the endDate of this job before 'firstSunday'?
                    thisTix.orphanedBefore = isBefore(thisTix.endDate, firstSunday)
                    if(thisTix.orphanedBefore) {
                        this.datesInThePast = true
                    }
                    //is the startDate of this job after 'EndOfNextWeek'?
                    thisTix.orphanedAfter = isAfter(thisTix.startDate, endOfNextWeek)
                    if(thisTix.orphanedAfter) {
                        this.datesInTheFuture = true
                    }

                    return thisTix
                })                 
                this.serviceItems = tixEvents

                } catch (err) {
                console.log(err)
                }
        },
        gotoJobProfile(id){
            this.$router.push({name: 'jobProfile', params : {id: id}})
        },
        calEventClick(item) {
            //console.log(item)
            this.selectedJob = item.originalItem
            this.$refs['event-modal'].show()

        },
        orphanedEventClick(item){
            console.log(item)
            this.selectedJob = item
            this.$refs['event-modal'].show()
        },
        hideModal() {
            this.$refs['event-modal'].hide()
        },
        valMechanic() {
            if(this.selectedJob.mechanic.length > 1){
                this.validForm.mechanic = true
            }
        },
        valEndDate() {
            if(!isBefore(this.selectedJob.endDate, this.selectedJob.startDate)){
                this.validForm.endDate = true
            }
        },
        async updateEvent() {
            //console.log(`startDate: ${this.selectedJob.startDate} , finishedDate: ${this.selectedJob.endDate}, status: ${this.selectedJob.status}`)
            
            if(this.selectedJob.mechanic.trim() === "") {
                this.validForm.mechanic = false
                return
            }
            
            //Make sure endDate is not before the startDate
            if(isBefore(this.selectedJob.endDate, this.selectedJob.startDate)){
                this.validForm.endDate = false
                return
            }

            this.hideModal()
            
                let payload = {}
                if(this.selectedJob.status == "pending") {
                    //first find and remove from "items" then update event on backend
                    let thisIndex = this.items.findIndex(x => x._id === this.selectedJob._id)
                    this.items.splice(thisIndex, 1)

                    //update pending jobs
                    this.$emit("updatePending", this.selectedJob.jobType)

                    payload = {
                        "dates.startDate" : null,
                        "dates.finishedDate" : null,
                        "status" : "pending",
                        "mechanic": null
                    }
                } else if(this.selectedJob.status == "finished") {
                    //remove job from local items
                    let thisIndex = this.items.findIndex(x => x._id === this.selectedJob._id)
                    this.items.splice(thisIndex, 1)

                    payload = {
                        "dates.startDate" : getUnixTime(this.selectedJob.startDate),
                        "dates.finishedDate" : getUnixTime(this.selectedJob.endDate),
                        "status" : this.selectedJob.status,
                        "mechanic" : this.selectedJob.mechanic
                    }
                } else {
                    payload = {
                        "dates.startDate" : getUnixTime(this.selectedJob.startDate),
                        "dates.finishedDate" : getUnixTime(this.selectedJob.endDate),
                        "status" : this.selectedJob.status,
                        "mechanic" : this.selectedJob.mechanic
                    }
                }
            
            //console.log(payload)
            
            try {
                const res = await this.$axios.put(`/job/${this.selectedJob._id}`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                if (res.status == "200") {
                    console.log('Update successful')
                    this.$bvToast.toast(`${this.selectedJob.name} updated successfully.`, {
                        title: `Success`,
                        variant: 'success',
                        solid: true
                    })
                    
                } else {
                    console.log(res.status)
                    
                }
            } catch (err) {
                console.log(err)
                this.$bvToast.toast(err, {
                        title: `An error has occurred`,
                        variant: 'danger',
                        solid: true
                    })
            }
             
        }
    },
    created () {
        this.getInProgressJobs()
    }
}
</script>

<style lang="scss" scoped> 
    .gantt {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 300px;
    }
    .redItem {
        background-color: tomato;
    }
    
    
</style>