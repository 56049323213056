<template>
<div>
<b-card :header="title" class="" no-body :border-variant="variant" :header-bg-variant="variant" header-text-variant="white">
    <b-list-group flush>
        <b-list-group-item v-for="job in pendingJobs" :key="job.contractId" >
            <b-row no-gutters>
                <b-col cols="10" md="10" class="text-truncate d-inline-block"  v-b-tooltip.hove.bottom.html :title="'Job type: '+job.jobType">
                    <font-awesome-icon :icon="job.icon.icon" /> <b-link :to="'/jobProfile/' + job._id">{{job.customer.lname}}, {{job.customer.fname}}</b-link>
                    <span class="font-weight-light">
                        <br><small>{{format(fromUnixTime(job.dates.depositDate), "MM/dd/yyyy")}}</small>
                    </span>
                </b-col>
                <b-col cols="2" md="2">
                    <div class="float-right"><b-button variant="outline-dark" size="sm" @click="showStartJob(job._id)"><font-awesome-icon icon="calendar-plus" /></b-button></div>
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</b-card>

<b-modal ref="event-modal" centered>
    <template #modal-header>
        <h5>Set the {{selectedJob.customer.lname}}, {{selectedJob.customer.fname}} job to 'Started'.</h5>
    </template>
    <b-container>
        <b-form>
            <b-form-group id="mechGroup" label-cols-sm="2" content-cols-sm="8" label="Mechanic" label-for="mechanic" :state="validForm.mechanic" invalid-feedback="You must enter a mechanic!">
                <b-form-input id="mechanic" v-model="selectedJob.mechanic" class="" :state="validForm.mechanic" @keypress="valMechanic()"></b-form-input>
            </b-form-group>
            <b-form-group id="startGroup" label-cols-sm="2" content-cols-sm="8" label="Start" label-for="startDate">
                <b-form-datepicker value-as-date id="startDate" v-model="selectedJob.startDate" :state="validForm.endDate"></b-form-datepicker>
            </b-form-group>

            <b-form-group id="endGroup" label-cols-sm="2" content-cols-sm="8" label="End" label-for="endDate" :state="validForm.endDate" invalid-feedback="End date must be after start date!">
                <b-form-datepicker value-as-date id="endDate" v-model="selectedJob.endDate" :state="validForm.endDate" @hidden="valEndDate()"></b-form-datepicker>
            </b-form-group>

        </b-form>
    </b-container>
    <template #modal-footer>
        <b-button size="sm" variant="outline-danger" @click="hideModal()">
            Cancel
        </b-button>
        <b-button size="sm" variant="primary" @click="updateEvent()">
            <font-awesome-icon icon="save"/> Update
        </b-button>
    </template>
</b-modal>
</div>

</template>

<script>

import { format, getUnixTime, fromUnixTime, addDays, isBefore } from 'date-fns'

export default {
    name: 'jobs-pending',
    props: {
        getJobType: String,
        title: String,
        variant: String,
    },
    data() {
        return {
            pendingJobs: [],
            selectedJob: {
                mechanic: ""
            },
            fromUnixTime: fromUnixTime,
            format: format,
            validForm: {
                mechanic: null,
                endDate: null
            }
        }
    },
    methods: {
        async getPendingJobs() {

            let endpoint = ""

            if(this.getJobType === "roofing") {
                endpoint = "/job/getPendingRoofing"
            } else if(this.getJobType === "siding"){
                //siding and windows
                endpoint = "/job/getPendingSiding"
            } else {
                //repairs
                endpoint = "/job/getPendingOther"
            }
            
            const res = await this.$axios.get(endpoint, {
                headers: { Authorization: `Bearer ${this.$state.token}` }
            })

            let jobs = res.data

            //jobs.sort((a,b) => (a.dates.depositdate > b.dates.depositDate) ? 1 : -1)
            
            jobs.forEach(job => {
                job.icon = this.getIcon(job.jobType)
            })

            this.pendingJobs = jobs
            
        },
        getIcon (jobType) {
            if(jobType.trim().toLowerCase() === 'rip reroof') {
                return {icon: 'people-roof', displayName: 'Rip Re-Roof'}
            } else if(jobType === 'Decking'){
                return {icon: 'grip-lines', DisplayName: 'Decking'}
            } else if(jobType === 'Roof Repair') {
                return {icon: 'people-roof', displayName: 'Roof Repair'}
            } else if(jobType === 'Other') {
                return {icon: 'network-wired', displayName: 'Other'}
            } else if(jobType === 'Railing') {
                return {icon: 'ruler-horizontal', displayName: 'Railing'}
            } else if(jobType === 'Gutters') {
                return {icon: 'tint', displayName: 'Gutters'}
            } else if(jobType === 'Gutter Repair') {
                return {icon: 'tint', displayName: 'Gutter Repair'}
            } else if(jobType === 'Gutter Cleaning') {
                return {icon: 'leaf', displayName: 'Gutter Cleaning'}
            } else if(jobType === 'Windows') {
                return {icon: 'fa-solid fa-table-cells-large', displayName: 'Windows'}
            } else if(jobType === 'Window Repair') {
                return {icon: 'fa-solid fa-table-cells-large', displayName: 'Window Repair'}
            } else if(jobType === 'Cover ReRoof') {
                return {icon: 'people-roof', displayName: 'Cover Re-Roof'}
            } else if(jobType === 'Siding') {
                return {icon: 'align-left', displayName: 'Siding'}
            } else if(jobType === 'Siding Repair') {
                return {icon: 'align-left', displayName: 'Siding Repair'}
            } else if(jobType === 'Trim Job') {
                return {icon: 'align-left', displayName: 'Trim Job'}
            } else if(jobType === 'New Construction Roof') {
                return {icon: 'people-roof', displayName: 'New Construction'}
            } else if(jobType === 'New Construction') {
                return {icon: 'people-roof', displayName: 'New Construction'}
            } else {
                return {icon: 'network-wired', displayName: 'Other'}
            }
        },
        showStartJob(jobId) {
            console.log(`Job id is ${jobId}`)
            let index = this.pendingJobs.findIndex(x => x._id === jobId);
            this.selectedJob = this.pendingJobs[index]
            this.selectedJob.startDate = new Date()
            this.selectedJob.endDate = addDays(new Date(), 1)
            this.selectedJob.mechanic = ""
            this.$refs['event-modal'].show()
        },
        hideModal() {
            this.$refs['event-modal'].hide()
            this.validForm.mechanic = null
            this.selectedJob.startDate = new Date()
            this.selectedJob.endDate = addDays(new Date(), 1)
            this.selectedJob.mechanic = ""
        },
        valMechanic() {
            if(this.selectedJob.mechanic.length > 1){
                this.validForm.mechanic = true
            }
        },
        valEndDate() {
            if(!isBefore(this.selectedJob.startDate, this.selectedJob.endDate)){
                this.validForm.endDate = true
            }
        },
        async updateEvent() {

            //Make sure mechanic isn't blank
            if(this.selectedJob.mechanic.trim() === "") {
                this.validForm.mechanic = false
                return
            }

            //Make sure endDate is not before startdate
            if(isBefore(this.selectedJob.endDate, this.selectedJob.startDate)){
                this.validForm.endDate = false
                return
            }

            let payload = {
                "mechanic": this.selectedJob.mechanic,
                "name" : `${this.selectedJob.customer.lname}, ${this.selectedJob.customer.fname}`,
                "dates.startDate" : getUnixTime(this.selectedJob.startDate),
                "startDate" : this.selectedJob.startDate,
                "dates.finishedDate" : getUnixTime(this.selectedJob.endDate),
                "endDate" : this.selectedJob.endDate,
                "status": 'started'
            }

            //remove job from local items
            let thisIndex = this.pendingJobs.findIndex(x => x._id === this.selectedJob._id)
            this.pendingJobs.splice(thisIndex, 1)
            this.hideModal()

            console.log(payload)
            console.log(`/job/${this.selectedJob._id}`)
            
            try {
                const res = await this.$axios.put(`/job/${this.selectedJob._id}`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                if (res.status == "200") {
                    console.log('Update successful')
                    this.$bvToast.toast(`${payload.name} updated successfully.`, {
                        title: `Success`,
                        variant: 'success',
                        solid: true
                    })
                    //update $state.calItems to show on calander immedietly
                    let newItem = {
                        _id: this.selectedJob._id,
                        mechanic : payload.mechanic,
                        name: payload.name,
                        startDate : payload.startDate,
                        endDate : payload.endDate,
                        status : 'started',
                        jobType: this.selectedJob.jobType
                    }
                    this.$state.calItems.push(newItem)
                    
                } else {
                    console.log(res.status)
                    
                }
            } catch (err) {
                console.log(err)
                this.$bvToast.toast(err, {
                        title: `An error has occurred`,
                        variant: 'danger',
                        solid: true
                    })
            }
        }
        
    },
    created() {
        this.getPendingJobs()
    }    
}
</script>

<style lang="scss" scoped>
    
</style>